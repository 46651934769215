@import "src/assets/styles/customs/variables";

.header {
  &:focus-visible {
    outline: none;
  }

  [data-theme="hc"] & {
    border-bottom: 1px solid $grayF7;
  }

  &.underlined {
    border-bottom: 1px solid $line;
  }

  &__content {
    height: 80px;
    display: flex;
    align-items: center;
    padding-right: 34px;

    [dir="rtl"] & {
      padding-left: 34px;
      padding-right: 24px;
    }
  }

  &__logo {
    display: flex;
    flex: 1;

    & > a {
      color: $textColor;
      height: 26px;
      display: flex;
    }
    &__container {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    & .nav-item {
      margin-right: 40px;

      [dir="rtl"] & {
        margin-right: 0;
        margin-left: 40px;
      }

      & .nav-item-text {
        color: $activeMenuTextColor;
      }

      a.active,
      &.active > a {
        font-weight: bold;
      }

      &:hover > a {
        font-weight: bold;
        cursor: pointer;
      }

      &.active > .body2 {
        font-weight: bold;
      }

      &:hover > .body2 {
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: $mobileMaxWidth) {
  .header {
    display: none;
  }
}

.profile {
  color: $linkTextColor;
  cursor: pointer;

  .cursor-default {
    cursor: default;
  }

  .cursor-pointer {
    cursor: pointer;
    pointer-events: all;
  }
  .block-user-event {
    pointer-events: none;
  }

  & > button {
    padding: 0;
    border: none;
    background-color: transparent;

    &:focus-visible {
      outline: 2px solid $point700;
    }
  }

  .profile__popup__user__name {
    // line break for longer names
    line-break: anywhere;
  }

  & > a {
    display: flex;
  }

  img {
    border-radius: 50%;
  }

  .profile-placeholder svg {
    fill: $grayTextColor;
    color: $grayTextColor;
  }

  &__popup {
    display: none;
    width: 392px;
    position: absolute;
    right: 24px;
    background: $defaultBackground;
    padding: 32px 40px;
    border: solid 1px $line;
    top: 81px;
    border-radius: 0 0 20px 20px;

    [dir="rtl"] & {
      right: initial;
      left: 24px;
    }

    &.open {
      display: block;
    }

    &__user {
      display: flex;
      align-items: center;

      &__icon {
        display: flex;

        img {
          border-radius: 50%;
        }
      }

      &__name {
        margin-left: 16px;
        font-size: 16px;

        [dir="rtl"] & {
          margin-right: 16px;
          margin-left: 0;
        }
      }
    }

    &__message {
      margin-top: 24px;
    }

    &__email {
      font-size: 16px;
      margin-top: 12px;
      line-break: anywhere;

      svg {
        fill: $gray75 !important;

        [dir="rtl"] & {
          transform: rotate(180deg);
        }
      }
    }

    .signout > span {
      font-weight: normal;
    }

    .horizontal-divider {
      width: 100%;
      height: 1px;
      background-color: $drawerLine;
      margin: 24px 0;
    }
  }
}

.sub-nav {
  position: absolute;
  left: 0;
  right: 0;
  background-color: $defaultBackground;
  z-index: 5;
  display: none;
  padding: 32px 264px 22px;
  height: 262px;
  // &.addTop {
  //   top: 180px !important;
  // }
  &.active {
    display: block;
    &::before {
      content: " ";
      width: 100%;
      top: -35px;
      position: absolute;
      height: 35px;
    }
  }

  &__content {
    margin-top: 14px;
    display: flex;

    &__left {
      display: inline-block;
      width: 60%;
    }

    @media (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
      &__left {
        margin: 0 20px;
        width: 45%;
      }
    }

    & .main-link {
      &__text {
        font-weight: 700;
        position: relative;

        &::after {
          content: " ";
          position: absolute;
          height: 2px;
          width: 100%;
          bottom: -1px;
          left: 0;
          background-color: $blk;
        }
      }
    }

    .sub-links {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        & > li {
          margin-top: 29px;

          &:first-child {
            margin-top: 0;
          }

          & a:hover,
          a:focus {
            font-weight: bold;
            border-bottom: 2px solid $linkTextColor;
          }
        }
      }
    }

    &__right {
      width: 40%;
      display: flex;

      &__text {
        display: flex;
        flex: 1;
      }
    }

    @media (min-width: $tabletPortraitMinWidth) and (max-width: $tabletPortraitMaxWidth) {
      &__right {
        width: 55%;
        margin: 0 20px;
      }
    }
  }
}
.header-container {
  position: fixed;
  z-index: 5;
  width: 100%;
  top: 0;
  background-color: $defaultBackground;
}
