@import "src/assets/styles/customs/variables";

.consignment-footer {
  position: absolute !important;
  bottom: 0 !important;
  display: flex;
  padding: 0 !important;
  min-height: 90px;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobileUnsupportedWidth) {
    position: relative !important;
  }
}
.footer {
  position: relative;
  background-color: $defaultBackground;
  height: 124px;
  width: 100%;
  padding: 36px 0;
  border-top: 1px solid $line;

  &.snap-scroll {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }

  &__content {
    display: flex;
    align-items: center;
    height: 100%;

    &__left {
      display: flex;
      flex: 1;

      &__links {
        display: flex;
        align-items: center;

        &__themes {
          display: flex;
          margin-left: 24px;

          [dir="rtl"] & {
            margin-left: 0;
            margin-right: 24px;
          }

          & .icon-link {
            cursor: pointer;
            display: flex;
            margin-right: 8px;
            padding: 0;
            border: none;
            background-color: transparent;

            &:focus-visible {
              outline: 2px solid $point700;
            }
          }

          & .icon-link-last {
            margin-right: 24px;
          }

          [dir="rtl"] & {
            direction: ltr;

            & .icon-link {
              margin-left: 24px;
              margin-right: 8px;
            }

            & .icon-link-last {
              margin-right: 0;
              margin-left: 0;
            }
          }
        }

        & .arrow-right {
          margin-left: 4px;

          [dir="rtl"] & {
            transform: rotate(180deg);
            margin-right: 4px;
          }

          & > span > svg path {
            [data-theme="hc"] & {
              color: $linkTextColor;
            }
          }
        }
      }

      &__copyright {
        margin-top: 16px;
        display: flex;

        & > span {
          opacity: 0.4;
          font-size: 14px;
          color: $textColor;
          font-family: $sharpSansFont !important;

          [data-theme="hc"] & {
            opacity: 1;
          }
        }
      }
    }

    .hc {
      background-color: #171717 !important;
      border: solid 1px #999 !important;
    }

    &__version {
      min-width: 648px;
      width: auto;
      height: 76px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      padding: 12px 16px;
      border-radius: 12px;
      border: solid 1px #84bcff;
      background-color: #eaf4ff;

      &__left {
        width: auto;
        min-width: 574px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .hc {
          color: #e5e5e5 !important;
          border: none !important;
        }
        &__flex {
          display: flex;
          align-items: center;
          gap: 12px;
          height: 23px;
          color: #000;

          p {
            display: inline;
            font-family: SamsungOne;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;

            p {
              display: inline;
              font-family: SamsungOne;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              opacity: 0.4;
            }
          }

          .hc {
            color: #171717 !important;
            background-color: #e5e5e5 !important;
          }

          &__badge {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            text-align: center;
            width: 38px;
            height: 23px;
            padding: 2px 4px;
            border-radius: 14px;
            color: $white;
            background-color: #2189ff;
          }
        }
      }

      &__right {
        cursor: pointer;
      }
    }

    &__right {
      font-size: 16px;
      width: auto;
      height: 76px;
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin: 0 0 0 289px;
      padding: 12px 16px;
      border-radius: 12px;
      border: solid 1px #999;
      a {
        width: auto;
      }

      p {
        display: inline;
        margin: 0;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background-color: transparent;

    &:focus-visible {
      outline: 2px solid $point700;
    }

    [data-theme="hc"] & {
      color: $linkTextColor;
    }

    & > span {
      font-size: 16px;
      [data-theme="hc"] & {
        color: $linkTextColor;
      }
    }

    &.sharp-sans > span {
      font-family: $sharpSansFont !important;
    }

    & .country {
      font-family: $sharpSansFont;
      display: flex;
    }
  }
}

.divider {
  width: 1px;
  height: 12px;
  background-color: $blk;
  margin: 0 15px;
  opacity: 0.4;
}
